











import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import SideCardComponent from '@/components/shared/SideCard.component.vue';
import CustomerManageContentComponent from '@/components/customer/CustomerManageContent.component.vue';

/**
 * SideCard wrapper for CustomerManageComponent
 */
@Component({
  components: {RJSelect, RJTextField, SideCardComponent, CustomerManageContentComponent},
})
export default class CustomerManageComponent extends Vue {

  @VModel({default: false})
  public showSideCard!: boolean;

  /**
   * The customer to manage
   */
  @Prop({default: () => new Customer()})
  public customer!: Customer;

  /**
   * This Prop is for enabling the router, to route to the customersDetailsView if wanted.
   * Introduced for the JobManagementComponent at the CalendarCalendarComponent, so as not to route after creating
   * a customer
   */
  @Prop({default: () => true})
  public enableRouter!: boolean;

  private closeManageComponent(customer: Customer) {
    this.$emit('exitModal', customer);
  }
}
