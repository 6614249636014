import { render, staticRenderFns } from "./CustomerManage.component.vue?vue&type=template&id=e20ce478&scoped=true&"
import script from "./CustomerManage.component.vue?vue&type=script&lang=ts&"
export * from "./CustomerManage.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e20ce478",
  null
  
)

export default component.exports