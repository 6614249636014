






















































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../../helper/ErrorMessageHandler.mixin';
import {mixins} from 'vue-class-component';
import {validationMixin} from 'vuelidate';
import {email, numeric, required, maxLength, minLength} from 'vuelidate/lib/validators';
import Customer from '@/models/Customer';
import {namespace} from 'vuex-class';
import {CountryStorage} from '@/misc/CountryStorage';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';
import Tenant from '@/models/Tenant';
import {customerStoreActions} from '@/stores/customer.store';
import {tenantStoreGetter} from '@/stores/tenant.store';

const CustomerStore = namespace('customer');
const TenantStore = namespace('tenant');

@Component({
  components: {RJSelect, RJTextField},
  mixins: [validationMixin],
  validations: {
    customerCopy: {
      name: {required},
      address: {
        street: {required},
        houseNo: {required, maxLength: maxLength(15)},
        postalCode: {numeric, required, maxLength: maxLength(5)},
        city: {required},
        country: {required},
      },
      // TODO: validate invoiceAddress
      // invoiceAddress: {
      //   required: requiredIf((vm) => !!vm),
      //   street: {required: requiredIf((vm) => !!vm)},
      //   houseNo: {required: requiredIf((vm) => !!vm), maxLength: maxLength(15)},
      //   postalCode: {required: requiredIf((vm) => !!vm), numeric, maxLength: maxLength(5)},
      //   city: {required: requiredIf((vm) => !!vm)},
      //   country: {required: requiredIf((vm) => !!vm)},
      // },
      contactPerson: {
        firstName: {required},
        lastName: {required},
        email: {
          email,
        },
        phone: {
          minLength: minLength(3),
        },
      },
    },
  },
})
export default class CustomerManageContentComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * This Prop is for enabling the router, to route to the customersDetailsView if wanted.
   * Introduced for the JobManagementComponent at the CalendarCalendarComponent, so as not to route after creating
   * a customer
   */
  @Prop({default: () => true})
  public enableRouter!: boolean;

  @Prop({default: () => new Customer()})
  public customer!: Customer;

  /**
   * A copy of the customer to work with, during the manage-dialog
   */
  public customerCopy: Customer = new Customer();
  public showComponent: boolean = false;

  public submitted: boolean = false;
  public hasInvoiceAddress: boolean = false;


  @CustomerStore.Action(customerStoreActions.CREATE_CUSTOMER_ACTION)
  public createCustomerAction!: (customer: Customer) => Promise<Customer>;
  @CustomerStore.Action(customerStoreActions.EDIT_CUSTOMER_ACTION)
  public editCustomerAction!: (customer: Customer) => Promise<Customer>;
  @CustomerStore.Action(customerStoreActions.DELETE_CUSTOMER_ACTION)
  public deleteCustomerAction!: (customer: Customer) => Promise<Customer>;

  /**
   * Countries for country select
   */
  public countries: Array<{ text: string, value: string }> = [];

  @TenantStore.Getter(tenantStoreGetter.ACTIVE_TENANT)
  private tenant!: Tenant;

  public created() {
    this.countries = CountryStorage;
  }

  /**
   * Event handler that saves the edition or the creation of user object
   */
  public async onSubmit(event: Event) {
    event.preventDefault();

    if (this.submitted) {
      return;
    }
    this.submitted = true;

    this.$v.customerCopy!.$touch();

    if (this.$v.customerCopy!.$invalid) {
      // if invalid scroll to first error input
      // OPTIMIZE the use of an css selector of a vuetify property is not always safe
    } else {
      try {
        if (this.customerCopy && this.customerCopy.tenant) {
          this.customerCopy = await this.editCustomerAction(this.customerCopy);
          this.$notifySuccessSimplified('CUSTOMER_MANAGE.NOTIFICATIONS.EDIT');
          this.$emit('exitModal', this.customerCopy);
        } else {
          this.customerCopy!.tenant = this.tenant.id;
          const createdCustomer = await this.createCustomerAction(this.customerCopy!);
          this.$notifySuccessSimplified('CUSTOMER_MANAGE.NOTIFICATIONS.CREATE');
          // if wanted, go to customerDashboard, otherwise not. If you are at the jobManagementComponent
          // you dont want to route to the dashboard after creating a customer
          if (this.enableRouter) {
            await this.$router.push({
              name: 'customerDashboard',
              params: {
                companyId: this.tenant.id,
                customerId: createdCustomer.id!,
              },
            });
          }
          this.$emit('exitModal', createdCustomer);
        }
      } catch (e) {
        if (this.$te(`CUSTOMER_MANAGE.NOTIFICATIONS.${e.status}.TITLE`)) {
          this.$notifyErrorSimplified(`CUSTOMER_MANAGE.NOTIFICATIONS.${e.status}`);
        } else {
          this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
        }
      }
    }
    this.submitted = false;
  }

  /**
   * Tells the parent to close the modal window
   */
  public cancelButtonClicked() {
    this.$emit('exitModal', null);
  }

  @Watch('customer', {immediate: true})
  private onCustomerChange() {
    this.customerCopy = this.customer.copy() as Customer;
    this.showComponent = true;
  }
}
